import React, {
    ReactNode,
    useContext,
    useEffect,
    useState,
    useRef,
} from 'react';
import { useHistory } from 'react-router-dom';
import {
    AuthenticationState,
    useAuthentication,
} from '../../../../../hooks/use-authentication';
import { charCheck } from '../../../../../services/valid-vin-service/ValidVinService';
import { decodeVin } from '../../../../../services/vin-decoder-service/VinDecoderService';
import localStorageWrapper from '../../../../utils/local-storage-wrapper/localStorageWrapper';
import { useVinSelectorContent } from '../../../vehicle-selector-fds/vin-selector/hook/use-vin-selector';
import { RedirectData } from '../../../../../support/components/FMALogin/FMALogin';
import { VinVehicleService } from '../../../../../services/vin-vehicle-service/vin-vehicle-service';
import ServerContext from '../../../../../contexts/serverContext';
import serverSideService from '../../../../../services/server-side-service/server-side-service';
import { findByAlias } from '../../../../../routes';
import { Modal } from '../../../../common/modal/modal';
import { useConfirmModalContent } from '../../../vehicle-selector-fds/hooks/use-confirm-modal-context';
import {
    notify,
    SlackMessages,
} from '../../../../../services/slack-message-service/SlackMessageService';
import { DSL_API_PATH } from '../../../../../constants';
import { useAnalytics } from '../../../../../hooks/use-analytics';
import { PageType } from '../../../view-template/page-type';
import { ShortcodeProviders } from '../../../../../services/shortcode-service/shortcode-service';
import { useBreakpoints } from '../../../../../hooks/use-breakpoints';
import { SyncMapsConsolidatedResponse } from '../../../../../views/sync-maps-updates-view/hooks/use-syncs-maps-status-updates';
import { useExperienceContent } from '../../../../../hooks/use-server-content';
import { CxYmm } from '../../../../../models/experiencefragments/cx-ymm';
import AppConfigurationService from '../../../../../services/app-configuration-service/app-configuration-service';
import VehicleTypeService from '../../../../../services/vehicle-type-service/vehicle-type-service';
import { FMButton } from '@own/fds-react';
import PreImagineVinInput from '../../../../common/pre-imagine-vin-input/pre-imagine-vin-input';
import { BlueCruiseLoadDataType } from '../../../../../support/pages/bluecruise/bluecruise';
interface Props {
    vinHeaderLabel?: string;
    vinPlaceholderLabel: string;
    submitLabel: string;
    tooltip?: ReactNode;
    isFmaLogin?: boolean;
    redirectData?: RedirectData;
    callbackMethod?: (
        syncMapsConsolidatedResponse: SyncMapsConsolidatedResponse
    ) => void;
    eventName?: string;
    page?: PageType;
    whereDoIFindVinCta?: string;
    handleVinSubmit?: (vin: string) => void;
    setVehicleTypeInformation?: (dataLoader: BlueCruiseLoadDataType) => void;
    selectedFmaVin?: string;
    findMyVinLabel?: string;
    findMyVinLink?: string;
    altLabel?: string;
}

export const getVinFromLocalStorageIfExists = () => {
    let queryVin = null;
    if (serverSideService.isClientSide()) {
        queryVin = sessionStorage.getItem('QUERY_VIN');
    }
    const vin = queryVin || localStorageWrapper.getItem('USER_VIN');
    return vin || '';
};

const PreImagineVINSelector = (props: Props) => {
    const breakpoints = useBreakpoints();
    const [dFlex, setdFlex] = useState<boolean | undefined>();
    const { handleVinSubmit } = props;
    useEffect(() => {
        if (props.page === 'SMASH' && breakpoints) {
            setdFlex(!breakpoints.mobile);
        }
    }, [props.page, breakpoints]);

    const [experienceContent] = useExperienceContent<CxYmm>(
        'img-cx-ymm',
        'cx-ymm',
        'img_cx_ymm'
    );

    const serverContext = useContext(ServerContext);
    const {
        currentLanguageRegionCode,
        current3LetterCountryCode,
    } = serverContext;
    const currentRoot = serverContext.root
        ? serverContext.root.substring(0, serverContext.root.length - 1)
        : '';
    const [authenticationState, ,] = useAuthentication();
    const vinSelectorContent = useVinSelectorContent();
    const confirmModal = useConfirmModalContent();
    const history = useHistory();
    const [modalVisible, setModalVisible] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const [vin, setVin] = useState(getVinFromLocalStorageIfExists());
    const [fireEvents] = useAnalytics();
    const inputFieldSubmitBtnref = useRef<HTMLButtonElement>(null);
    const onSubmit = (vin: string) => {
        if (confirmModal && !charCheck(vin)) {
            setModalVisible(!modalVisible);
            sessionStorage.removeItem('QUERY_VIN');
            return;
        }
        if (
            props.eventName &&
            props.eventName !== 'owner-dyf-landing-page-cta'
        ) {
            let shortcodeProviders: ShortcodeProviders = {
                ctaType: 'vin',
            };
            if (props.page && props.page === 'SMASH') {
                shortcodeProviders = {
                    smashHomeClick: {
                        smashHomeSearchContentCta: 'vin',
                    },
                };
            }
            fireEvents(props.eventName, undefined, shortcodeProviders, false);
        }
        localStorageWrapper.setItem('USER_VIN', vin);
        switch (props.redirectData?.id) {
            case 'recalls':
                new VehicleTypeService()
                    .request(
                        vin,
                        currentLanguageRegionCode,
                        current3LetterCountryCode?.toUpperCase()
                    )
                    .then(() => {
                        if (props.redirectData) {
                            if (props.redirectData.path?.includes(':vin?')) {
                                window.location.href = `${currentRoot}${props.redirectData.path.replace(
                                    ':vin?/',
                                    ''
                                )}`;
                            } else if (
                                props.redirectData.path?.includes(
                                    ':vinOrModel?/'
                                )
                            ) {
                                window.location.href = `${currentRoot}${props.redirectData.path.replace(
                                    ':vinOrModel?/:year?/',
                                    ''
                                )}`;
                            } else {
                                window.location.href = `${currentRoot}${props.redirectData.path}`;
                            }
                        }
                    })
                    .catch(error => {
                        notify(
                            SlackMessages.VIN_API_FAILURE +
                                vin +
                                ' for api ' +
                                DSL_API_PATH.VEHICLE_TYPE
                        );
                        console.error('error', error.message);
                        setErrorMessage(vinSelectorContent?.invalidVin);
                    });
                break;
            case 'owner-manual':
                sessionStorage.setItem('QUERY_VIN', vin);
                window.location.href = `${currentRoot}${props.redirectData.path}`;
                break;
            case 'recalls-details':
            case 'sync-maps':
                new VehicleTypeService()
                    .request(
                        vin,
                        currentLanguageRegionCode,
                        current3LetterCountryCode?.toUpperCase()
                    )
                    .then(() => {
                        props.handleVinSubmit && props.handleVinSubmit(vin);
                    })
                    .catch(error => {
                        console.error('error', error.message);
                        setErrorMessage(vinSelectorContent?.invalidVin);
                    });
                break;
            case 'blueCruise':
                props.setVehicleTypeInformation &&
                    props.setVehicleTypeInformation({
                        vehicleTypeInfo: undefined,
                        loading: true,
                        errorMessage: '',
                    } as BlueCruiseLoadDataType);
                new VehicleTypeService()
                    .request(
                        vin,
                        currentLanguageRegionCode,
                        current3LetterCountryCode?.toUpperCase()
                    )
                    .then(vehicleTypeInfo => {
                        props.setVehicleTypeInformation &&
                            props.setVehicleTypeInformation({
                                vehicleTypeInfo: vehicleTypeInfo,
                                loading: false,
                                errorMessage: '',
                            } as BlueCruiseLoadDataType);
                    })
                    .catch(() => {
                        setErrorMessage(vinSelectorContent?.invalidVin);
                        props.setVehicleTypeInformation &&
                            props.setVehicleTypeInformation({
                                vehicleTypeInfo: undefined,
                                loading: false,
                                errorMessage: vinSelectorContent?.invalidVin,
                            } as BlueCruiseLoadDataType);
                    });

                break;

            case 'learning-hub':
                new VinVehicleService(vin)
                    .getYearAndModel()
                    .then(vehicleData => {
                        if (props.redirectData) {
                            const model = vehicleData.getOwnerInformationByVinResult.model.toLowerCase();
                            const year =
                                vehicleData.getOwnerInformationByVinResult
                                    .modelYear;
                            let isSupportedModel = false,
                                isSupportedModelYear = false;
                            let modelIs = '',
                                yearIs = '';
                            experienceContent &&
                                experienceContent.modelYearInclude.forEach(
                                    modelYears => {
                                        if (modelYears.modelYear == year) {
                                            isSupportedModelYear = true;
                                            yearIs = modelYears.modelYear;
                                            modelYears.supportedModels.forEach(
                                                supportedModel => {
                                                    if (
                                                        model.includes(
                                                            supportedModel.seoKey
                                                        )
                                                    ) {
                                                        isSupportedModel = true;
                                                        modelIs =
                                                            supportedModel.seoKey;
                                                    }
                                                }
                                            );
                                        }
                                    }
                                );
                            if (isSupportedModel && isSupportedModelYear) {
                                if (
                                    props.eventName ==
                                        'owner-dyf-landing-page-cta' &&
                                    vin
                                ) {
                                    fireEvents(
                                        props.eventName,
                                        undefined,
                                        {
                                            cxVehicle: {
                                                cxVinOrYmm: vin,
                                            },
                                        },
                                        false
                                    );
                                }
                                history.push(
                                    props.redirectData.path
                                        .replace(':model', modelIs)
                                        .replace(':year', yearIs)
                                );
                            } else if (
                                isSupportedModelYear &&
                                !isSupportedModel
                            ) {
                                throw new Error(
                                    experienceContent?.modelNameMismatch
                                );
                            } else {
                                throw new Error(
                                    experienceContent?.modelYearMismatch
                                );
                            }
                        }
                    })
                    .catch(error => {
                        if (
                            error.message ==
                            experienceContent?.modelNameMismatch
                        ) {
                            console.error('error', error.message);
                            setErrorMessage(
                                experienceContent?.modelNameMismatch
                            );
                        } else if (
                            error.message ==
                            experienceContent?.modelYearMismatch
                        ) {
                            console.error('error', error.message);
                            setErrorMessage(
                                experienceContent?.modelYearMismatch
                            );
                        } else {
                            console.error('error', error.message);
                            setErrorMessage(vinSelectorContent?.invalidVin);
                        }
                    });
                break;
            case 'TSB':
                if (handleVinSubmit) {
                    handleVinSubmit(vin);
                }
                break;
            default:
                history.push(
                    findByAlias('VehicleVinView').replace(':vin', vin)
                );
                break;
        }
    };
    const validateVin = (vin?: string) => {
        setVin(vin || '');
        inputFieldSubmitBtnref.current?.click();
    };
    useEffect(() => {
        if (props.redirectData) {
            if (sessionStorage.getItem('QUERY_VIN')) {
                if (errorMessage) sessionStorage.removeItem('QUERY_VIN');
                else validateVin(sessionStorage.getItem('QUERY_VIN') || '');
            }
        }
    }, [props.redirectData, errorMessage, vinSelectorContent]);
    useEffect(() => {
        if (props.redirectData?.id === 'sync-maps') {
            if (
                authenticationState === AuthenticationState.Authenticated &&
                vin !== props.selectedFmaVin &&
                props.selectedFmaVin !== ''
            ) {
                if (serverSideService.isClientSide()) {
                    sessionStorage.removeItem('QUERY_VIN');
                    localStorageWrapper.removeItem('USER_VIN');
                }
                setVin('');
                setErrorMessage('');
            }
        }
    }, [props.selectedFmaVin]);
    const inputIsntFullVin = () => {
        const vinRegex = new AppConfigurationService().isRegionEU()
            ? new RegExp('^[a-zA-Z0-9 -]+$')
            : new RegExp('^[A-Za-z0-9]{17}$');
        if (!vinRegex.test(vin)) {
            return true;
        }
        return false;
    };
    return (
        <div className="pre-imagine-ymm-vin-container">
            <div
                className={`pre-imagine-ymm-vin-body ${dFlex ? 'd-flex' : ''}`}
            >
                <div
                    className={`pre-imagine-vin-input-container ${
                        dFlex ? 'd-flex' : ''
                    }`}
                >
                    {vinSelectorContent && (
                        <PreImagineVinInput
                            ref={inputFieldSubmitBtnref}
                            name="vin-selector"
                            vinSelectorContent={vinSelectorContent}
                            vin={vin}
                            setParentVin={setVin}
                            placeholder={''}
                            errorMessage={errorMessage}
                            setParentError={setErrorMessage}
                            onSubmit={onSubmit}
                            decodeVin={!confirmModal}
                            altLabel={props.altLabel}
                        />
                    )}
                    {confirmModal && (
                        <Modal
                            name={'badCharModal'}
                            role="dialog"
                            aria-label={confirmModal?.ariaLabel}
                            onClose={() => {
                                setModalVisible(!modalVisible);
                            }}
                            primaryBtnLabel={confirmModal?.primaryButtonLabel}
                            secondaryBtnLabel={
                                confirmModal?.secondaryButtonLabel
                            }
                            onPrimaryBtnClick={() => {
                                const decodedVin = decodeVin(vin);
                                onSubmit(decodedVin);
                                setModalVisible(!modalVisible);
                            }}
                            onSecondaryBtnClick={() => {
                                setModalVisible(!modalVisible);
                            }}
                            isVisible={modalVisible}
                            chevron={true}
                        >
                            <div className="modal-title">
                                {confirmModal?.title}
                            </div>

                            {confirmModal?.body.replace(
                                ':decodedVin',
                                decodeVin(vin)
                            )}
                        </Modal>
                    )}
                </div>
                {props.findMyVinLink && props.findMyVinLabel && (
                    <FMButton
                        type={'tertiary'}
                        label={props.findMyVinLabel}
                        href={props.findMyVinLink}
                    ></FMButton>
                )}
                <FMButton
                    type={'primary'}
                    label={props.submitLabel}
                    chevron={'right'}
                    ariaLabel={props.submitLabel}
                    data-test-id={'vin-submit-button'}
                    disabled={inputIsntFullVin()}
                    onClick={() => {
                        validateVin(vin);
                    }}
                ></FMButton>
            </div>
            {props?.whereDoIFindVinCta && (
                <div
                    className="find-vin-cta"
                    dangerouslySetInnerHTML={{
                        __html: props?.whereDoIFindVinCta || '',
                    }}
                />
            )}
        </div>
    );
};

export default PreImagineVINSelector;
