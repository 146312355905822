import React, {
    ChangeEvent,
    forwardRef,
    Ref,
    useRef,
    useEffect,
    useState,
} from 'react';
import { KEYBOARD_KEYS } from '../../../constants';
import AppConfigurationService from '../../../services/app-configuration-service/app-configuration-service';
import isValidVinOrReg, {
    vinStartsWithWFO,
} from '../../../services/valid-vin-service/ValidVinService';
import isSupportedVin, {
    decodeVin,
} from '../../../services/vin-decoder-service/VinDecoderService';
import { VinSelectorProps } from '../../sections/vehicle-selector-fds/vin-selector/hook/use-vin-selector';
import { FMInput } from '@own/fds-react';
import { PageType } from '../../sections/view-template/page-type';
interface VinInputProps {
    name: string;
    vinSelectorContent?: VinSelectorProps;
    label?: string;
    ariaLabel?: string;
    vin?: string;
    placeholder?: string;
    errorMessage?: string;
    onSubmit?: (vin: string) => void;
    setParentVin: React.Dispatch<React.SetStateAction<string>>;
    setParentError: React.Dispatch<React.SetStateAction<string | undefined>>;
    focusOnLoad?: boolean;
    decodeVin?: boolean;
    altLabel?: string;
}
interface FMVinInputProps {
    pageType?: PageType;
    footerText?: string;
}

const PreImagineVinInput = forwardRef(
    (props: VinInputProps & FMVinInputProps, ref: Ref<HTMLButtonElement>) => {
        const regionEU = new AppConfigurationService().isRegionEU();
        const inputRef = useRef<HTMLInputElement>(null);

        useEffect(() => {
            if (props.focusOnLoad) {
                inputRef.current?.focus();
            }
        }, [props.focusOnLoad]);

        const validVin = (vin?: string) => {
            if (!vin) return props.vinSelectorContent?.noEmptyField;
            if (vin.length <= 10) {
                if (!isValidVinOrReg(vin, regionEU))
                    return props.vinSelectorContent?.invalidVin;
                else props.onSubmit?.(vin);
            }
            if (regionEU && vinStartsWithWFO(vin))
                return props.vinSelectorContent?.vinWfoError;
            const decodedVin = decodeVin(vin);
            if (!isSupportedVin(decodedVin))
                return props.vinSelectorContent?.vinNotFordLincolnMercury;

            if (
                props.decodeVin &&
                decodedVin !== decodedVin &&
                props.setParentVin
            )
                props.setParentVin(decodedVin);

            if (props.onSubmit) props.onSubmit(decodedVin);
        };

        const validateOnEnter = () => {
            const vinErrorMessage = validVin(props.vin);
            if (vinErrorMessage) {
                props.setParentError(vinErrorMessage);
                setInputState('error');
            }
        };
        const onEnter = () => {
            props.setParentError('');
            validateOnEnter();
        };

        const onInputKeyPress = (
            event: React.KeyboardEvent<HTMLInputElement>
        ) => {
            if (event.key === KEYBOARD_KEYS.ENTER) {
                props.setParentError('');
                validateOnEnter();
            }
        };
        const [inputState, setInputState] = useState<any>('standard');
        return (
            <div className="pre-imagine-vin-input-field-container">
                {props.vinSelectorContent && (
                    <FMInput
                        footer={props.errorMessage}
                        ref={inputRef}
                        state={inputState || ''}
                        maxLength={17}
                        header={
                            props.altLabel ||
                            props.label ||
                            props.vinSelectorContent.vinInputLabel ||
                            'VIN'
                        }
                        label={props.label}
                        ariaLabel={
                            props.ariaLabel ||
                            props.vinSelectorContent.ariaLabel ||
                            'VIN'
                        }
                        name={`vin-field-${props.name}`}
                        type="text"
                        value={props.vin}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            props.setParentError('');
                            props.setParentVin &&
                                props.setParentVin(
                                    e.currentTarget.value.toUpperCase()
                                );
                            setInputState('standard');
                        }}
                        placeholder={''}
                        onKeyUp={onInputKeyPress}
                    />
                )}

                <button
                    ref={ref}
                    id={`vin-input-${props.name}-submit`}
                    style={{ display: 'none' }}
                    onClick={onEnter}
                ></button>
            </div>
        );
    }
);
PreImagineVinInput.displayName = 'PreImagineVinInput';
export default PreImagineVinInput;
